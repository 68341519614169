import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import App from "./App";
import Home from "./pages/Home";
import Locations from "./pages/Locations";
import Products from "./pages/Products";
import "./styles/style.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
    ],
  },
  {
    path: "/locations",
    element: <App />,
    children: [
      {
        path: "/locations",
        element: <Locations />,
      },
    ],
  },
  // {
  //   path: "/products",
  //   element: <App />,
  //   children: [
  //     {
  //       path: "/products",
  //       element: <Products />,
  //     },
  //   ],
  // },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

const item = document.getElementById("root");